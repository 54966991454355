const awsconfig = {
    Auth: {
        region: process.env.VUE_APP_AWS_REGION,
        userPoolId: process.env.VUE_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID
    }
};

export default awsconfig;

