
// this fixes a bug, user login state on page reload
import {onAuthUIStateChange} from "@aws-amplify/ui-components";

export function initAuthUser(there, thereAuth, thereAuthState, runMethod ) {

    if(there.authState === undefined) {

        thereAuth.currentAuthenticatedUser().then(authData => {
            console.log('Auth.currentAuthenticatedUser, user signed in? %s', there.$store.getters.userSignedIn)
            there.authState = thereAuthState.SignedIn;
            there.user = authData;

            if (runMethod !== undefined && there.$store.getters.userSignedIn == true){
                console.log("runMethod was defined on userSignedIn and user signedIn. So call it")
                runMethod()
            }

        }, (error) => { console.log("Auth error:  %s",error) })
    }

}

// will be triggered on state change - happens also on reload
export function changeOnAuthEvent(there, runOnChange) {

    there.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
        if (there.$store.state != undefined){
            there.$store.commit("updateAuthState", {authState,authData});
        }
        console.log('user login state updated')
        console.log('user signed in? %s', there.$store.getters.userSignedIn)

        // trigger a followup function after login / logout
        if (runOnChange !== undefined){
            runOnChange()
        }


    })

}


