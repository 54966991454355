import { createStore } from 'vuex'
import {Auth} from "aws-amplify";


export default createStore({


  state: {

    // central auth store
    auth: {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      userAccessToken: undefined,
      userIdToken: undefined,
      userRefreshToken: undefined
    },

    // central cache
    cache: {},
    cacheExecFor: {},

  },
  mutations: {
    updateAuthState(state, { authState, authData }){
      state.auth.authState = authState;
      state.auth.user = authData;
    },

    updateUserTokens(state){
      Auth.currentSession().then(data => {
        console.log('idToken: %s',data.getIdToken().jwtToken)
        state.auth.userIdToken = data.getIdToken().jwtToken
      }).catch(err => console.log(err));

      Auth.currentSession().then(data => {
        console.log('refreshToken: %s',data.getRefreshToken().token)
        state.auth.userRefreshToken = data.getRefreshToken().token
      }).catch(err => console.log(err));


      Auth.currentSession().then(data => {
        console.log('accessToken: ',data.getAccessToken().jwtToken)

        state.auth.userAccessToken = data.getAccessToken().jwtToken
      }).catch(err => console.log(err));
    },

    removeUserTokens(state){
      console.log('Removing tokens')
      state.auth.userAccessToken = undefined
      state.auth.userRefreshToken = undefined
      state.auth.userIdToken = undefined
    },

    // central cache
    // usage: this.$store.commit("setCacheValue", {key, value} );
    setCacheValue(state, {key, value, seconds}){

      const timeStored = new Date();
      const timeStoredSecs = Math.trunc( timeStored/1000 );

      console.log('CACHE SET: key: %s, secs: %s', key, seconds)

      state.cache[key] = {
        "value": value,
        "timeStored": timeStoredSecs,
        "secondsKeep": seconds,
      };
    },

    // collects function-refs which must be
    // executed when setCacheValue is received.
    // Returns true if it was the first entry
    cache_ExecOnPromisedValue(state, {key, exec, result}){

      console.log('CACHE ADD EXEC: key: %s', key)

      result.first = false;
      if (!(key in state.cacheExecFor) ){ // empty, init
        state.cacheExecFor[key] = []
        result.first = true
        console.log('CACHE ADD - first!')
      }

      state.cacheExecFor[key].push(exec)
      console.log('CACHE ADD - size: %s', state.cacheExecFor[key].length)

      // can not return something. So the result pointer is used
    },

    execWatingCacheExec(state, { key } ){
      console.log('CACHE EXECUTION for key: %s', key)

      while(state.cacheExecFor[key].length > 0) {
        const exec = state.cacheExecFor[key].pop();
        exec(state.cache[key].value)
      }

      console.log('OK')

      delete state.cacheExecFor[key]

    }


  },

  actions: {
  },
  modules: {
  },
  getters: {
    userSignedIn(state){
      return state.auth.authState === "signedin" ? true : false;
    },
    userName(state){
      return state.auth.authState === "signedin" ? state.auth.user.attributes.email : "";
    },
    userId(state){
      return state.auth.authState === "signedin" ? state.auth.user.username : "";
    },
    userAccessToken(state){
      return state.auth.userAccessToken
    },
    userJWTToken(state){
      return state.auth.userJWTToken
    },
    userIdToken(state){
      return state.auth.userIdToken
    },


    // central cache
    // usage: this.$store.getters.getCacheValue('foo')
    getCacheValue: (state) => (key) => {
      let res = undefined;

      console.log('CACHE GET try: key: %s', key)

      if ((key in state.cache) && (state.cache[key] != undefined)){
        const item = state.cache[key];

        const timeStored = item['timeStored'];
        const secondsKeep = item['secondsKeep'];
        const now = Math.trunc( new Date()/1000 );

        const secondsDelta = now - timeStored

        if (secondsDelta <= secondsKeep){ // still valid?
          res = item['value'];
          console.log('CACHE GET state: OK - seconds since storage: %s', secondsDelta)
        }else{
          console.log('CACHE GET state: timeout')
        }

      }
      return res;
    },





  }
})
