
// takes the header and finds the seconds of cache.
// default is 0 seconds
export function extractCachetimeFromHeaders(headers){
    const cacheControl = headers['cache-control'];
    let res = 0; // zero time to cache

    console.log('cacheControl: ', cacheControl)

    if (cacheControl != undefined){

        var rx = /max-age=([0-9]+)/g;
        var arr = rx.exec(cacheControl);

        if (arr != undefined && arr.length > 0){
            res = arr[1]
        }

    }

    return res
}

// wrapper to store data in cache with timeout
export function cacheSet(that, key, value, seconds){
    that.$store.commit("setCacheValue", {key, value, seconds} );
}

// also sets the value, but also runs all waiting execs
export function cacheSetAndExecute(that, key, value, seconds){
    that.$store.commit("setCacheValue", {key, value, seconds} );
    that.$store.commit("execWatingCacheExec", {key} );
}


// wrapper to get stored data from cache if no timeout
export function cacheGet(that, key){
    return that.$store.getters.getCacheValue(key)
}

export function cache_firstExecOnPromisedValue(that, key, exec){
    let result = {}
    that.$store.commit("cache_ExecOnPromisedValue", {key, exec, result} );
    return result.first
}